import React from "react"

import WOW from 'wowjs';
import { Helmet } from "react-helmet"
import { findDOMNode } from "react-dom";
import { Link } from 'gatsby'

import Topbar from '../components/topbar'
import Footer from '../components/footer'
import { APP_URI } from '../configs';

import { Dropdown, Checkbox } from "semantic-ui-react";

import SelectableList from "../components/selectableList";

import UserTopbar from "../components/userTopbar";

import UserContext from "../userContext";

import msj from '../images/msj-payment.svg'

import py1 from '../images/py1.svg'
import py2 from '../images/py2.svg'
import py3 from '../images/py3.svg'

import troops from '../images/troops_list.svg'
import troopers from '../images/troopers_list.svg'

import cup from '../images/cup-icon.svg'
import cupYellow from '../images/cup-icon-yellow.svg'

import checked from '../images/has-feature-icon.svg'
import noChecked from '../images/hasnt-feature-icon.svg'

import plan1 from '../images/plan-1.png'
import plan2 from '../images/plan-2.png'
import plan3 from '../images/plan-3.png'

import '../scss/cup-sign-up.scss'

export default class cupSignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      following: false,
      checked: '-',
      errors: [],
      player: false,
      step: 1,
      client: false,
    };
    this.references = {
      hero: React.createRef(),
      services: React.createRef(),
      numbers: React.createRef(),
      features: React.createRef(),
      logos: React.createRef(),
      contact: React.createRef(),
    }
  }

  handleNext = (user) => {
    var newValue = this.state.step + 1;


    if (this.state.step === 6) {
      newValue = 0;
    }


    this.setState({
      checked: '-',
      step: newValue
    })
  }


  available_countries = () => {

    return [
      { key: '1', value: '1', text: "Argentina" },
      { key: '2', value: '2', text: 'Chile' },
      { key: '3', value: '3', text: 'Colombia' },
      { key: '4', value: '4', text: 'Ecuador' },
      { key: '5', value: '5', text: 'Peru' },
      { key: '6', value: '6', text: 'Uruguay' },
      { key: '7', value: '7', text: 'Paraguay' },
      { key: '8', value: '8', text: 'Bolivia' },
    ]
  }

  available_troopers = () => {

    return [
      { key: '1', value: '1', text: "Aydan_" },
      { key: '2', value: '2', text: 'r1vox' },
      { key: '3', value: '3', text: 'pixiee_97' },
      { key: '4', value: '4', text: 'tomi.gz' },
      { key: '5', value: '5', text: 'slaug-horse' },
      { key: '6', value: '6', text: 'lauti-casla' },
      { key: '7', value: '7', text: 'mike-retric' },
      { key: '8', value: '8', text: 'joseul_lol' },
    ]
  }

  componentDidMount() {
    this.setState({
      client: true,
    })
    if (window.location.href.indexOf("checkout") > -1) {
      this.setState({
        step: 1
      })
    }
  }

  handleBack = (e) => {
    if (this.state.step > 0) {
      this.setState({ step: (this.state.step - 1) })
    }
  }


  scrollToReference(ref) {
    const node = findDOMNode(ref.current);
    node.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    });
  }

  render() {
    return (
      <>
        {this.state.client &&
          <div className="cup-sign-up">
            <UserContext.Consumer>
              {userObject => (
                <>
                  <div className="content troop-pro-content">
                    <UserTopbar onClick={() => this.setState({ player: true })} onClose={() => this.setState({ player: false })} player={this.state.player} user={userObject} profilePic={userObject?.userObject?.picture || null} />
                    <Helmet>

                    </Helmet>

                    <section ref={this.references.hero} className="hero--section">
                      <div className="wrapper">
                        <div className="flex--container">
                          <h1 className="hero--title">Sumate a la Troop CUP 21</h1>
                          <p className="hero--text">
                            Conectá y conocé a otros jugadores, creá equipos competitivos, conocé nuevos
                            compañeros y competí para convertirte en el gamer que siempre soñaste.
                          </p>
                        </div>
                      </div>
                    </section>


                    <section ref={this.references.features} className="signup--section">
                    <h1 className="hero--title">Inscripción Troop CUP 21</h1>
                    <p className="subtitle">
                    <span>IMPORTANTE:</span>
                    <br/>
- Las troops sólo pueden ser inscriptas al torneo por su <span class="green">administrador</span> (o alguno de sus administradores, de haber más de uno).
<br/>- Recordá que cada juego tiene una capacidad máxima de participantes, que podés consultar en la <Link><span class="green"> información de la Troop CUP 21.</span></Link>
                    </p>
                    </section>





                    <section ref={this.references.features} className="signup--section">
                      <div className="wrapper">
                        <div className="signup--box">
                          <div className="back--container">
                          </div>

                          <div className="step step-1">
                            <h2 className="signup--title">¿Listo para poner tu Troop en acción? </h2>
                            <h4 className="form--subtitle">Seguí los pasos a continuación y empezá
                              a disfrutar de ser un trooper PRO.</h4>
                            <h4 className="signup--subtitle">Seguí los pasos a continuación y empezá
                              a disfrutar de ser un trooper PRO.</h4>
                            <div className="games--selectable--container">
                              <SelectableList selectablesList={['CSGO', 'Call of Duty', 'Fortnite', 'League of Legends']} />
                            </div>
                            <h4 className="signup--subtitle">Seleccioná tu troop</h4>
                            <img src={troops} />
                            <h4 className="signup--subtitle">¿Quiénes participarán en el torneo?</h4>
                            <img src={troopers} />
                            <h4 className="signup--subtitle">¿Habrá jugadores suplentes? (Podés seleccionar hasta dos)</h4>
                            <img src={troopers} />
                          </div>
                          
                          <div className="terms--container">
                                  <Checkbox />
                                  <p>He leído y acepto todos los <span className="bold">Términos y Condiciones </span> de la TROOP CUP 21.</p>
                          </div>

                          <Link to="/feed?callback-cup" className="button--global">Inscribirme</Link>
                        </div>
                      </div>
                    </section>


                    <Footer />
                  </div>
                </>
              )}
            </UserContext.Consumer>
          </div>
        }
      </>
    );
  }
}
